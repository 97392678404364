import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropdown-basic',
  templateUrl: './dropdown-basic.component.html',
  styleUrls: ['./dropdown-basic.component.sass']
})
export class DropdownBasicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
